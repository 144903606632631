import React from 'react';
import STLViewer from 'stl-viewer'
import useReactSimpleMatchMedia from 'react-simple-matchmedia'

const Logo3D = () => {

    const isMobile = useReactSimpleMatchMedia('(max-width: 768px)');

    return (
        <STLViewer
            model='https://storageapi.fleek.co/adiusz-team-bucket/logoMin.stl'
            width={isMobile ? 135 : 350}
            height={isMobile ? 135 : 350}
            modelColor='#FFFFFF'
            backgroundColor="#f5f5f5"
            lightColor="#fff"
            cameraX={100}
            cameraY={100}
            cameraZ={500}
            lights={[1,1,1]}
            rotationSpeeds={[0.005, 0, 0.005]}
            rotate={true}
            orbitControls={true}
        />
    )
};

export default Logo3D;