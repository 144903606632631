import styled from 'styled-components';

export const ButtonStyles = styled.button`
    background: ${({ blue }) =>  blue ? 'var(--blue)' : 'var(--black)'};
    border-radius: 50px;
    color: var(--white);
    padding: .5em 3em;
    border: ${({ hero }) => hero ? '2px solid var(--white)' : 'none'};
    width: ${({ hero }) => hero ? 'max-content' : 'unset'};
    margin: ${({ hero }) => hero ? '5px 0' : 'unset'};
    opacity: ${({ opacity0 }) => opacity0 && '0'};
    font-weight: bold;
    cursor: pointer;
    transition: color .3s ease-out, background .3s ease-out;
    
    &:hover {
        background: ${({ blue}) =>  blue ? 'var(--white)' : 'var(--white)'};
        color: ${({ blue}) =>  blue ? 'var(--blue)' : 'var(--black)'};
    }
    
    @media (max-width: 768px) {
        font-size: .7rem;
    }
`;