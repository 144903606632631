import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';
import useReactSimpleMatchMedia from 'react-simple-matchmedia'
import Container from "../Container/Container";
import SectionHeader from "../Typography/SectionHeader";
import useOnScreen from '../../utils/useOnScreen';

const VideoSection = styled.section`
    display: block;
    padding: 92px 0;
    background: var(--gray);
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 500px;
  margin-top: 48px;
  
  .video {
      opacity: 0;
  }
  
  @media (max-width: 768px) {
      height: 300px;
  }
  
  iframe {
    width: 100%;
    //height: 500px;
    border-radius: 50px;
  }
`;

const Videos = ({ colorGray }) => {

    const isMobile = useReactSimpleMatchMedia('(max-width: 768px)');

    useEffect(() => {
        gsap.set('.video', { scale: .8, y: 25, autoAlpha: 0, duration: 1.5, ease: 'power4.out' });
        gsap.to('.video', {
            scrollTrigger: {
                onEnter: () => gsap.to('.video', { scale: 1, y: 0, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.video', { scale: .8, y: 25, autoAlpha: 0 }),
                trigger: '.video',
                start: '-15% 75%',
                end: '-5% 85%',
            }
        });
        //
        // gsap.fromTo('.video', { }, { scale: 1, autoAlpha: 1, y: 0, duration: 1.5, scrollTrigger: {
        //         trigger: '.video',
        //         start: '-30% 75%',
        //         end: '-30% 75%',
        //     }
        // })

    }, []);

    const ref = useRef();

    const onScreen = useOnScreen(ref, '-100px');

    return (
        <VideoSection id="video_section" ref={ref} colorGray={colorGray}>
            <Container>
                <SectionHeader>
                    Zobacz jak to działa!
                </SectionHeader>
                <VideoWrapper className="video">
                    {onScreen ? (
                        <div>
                            {/*<YTiFrame src="https://www.youtube.com/embed/s93Esmuq9G4" title="promo video" width="100%" height="100%" />*/}
                            <iframe
                                title="YouTube promo video"
                                src="https://www.youtube.com/embed/s93Esmuq9G4"
                                frameBorder="0"
                                height={isMobile ? '300px' : '500px'}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                loading="lazy"
                            >
                            </iframe>
                        </div>
                    ) : (
                        <h1>Ładowanie filmu...</h1>
                    )}

                    {/*<iframe*/}
                    {/*    title="YouTube promo video"*/}
                    {/*    src="https://www.youtube.com/embed/s93Esmuq9G4"*/}
                    {/*    frameBorder="0"*/}
                    {/*    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                    {/*    allowFullScreen*/}
                    {/*    loading="lazy"*/}
                    {/*>*/}
                    {/*</iframe>*/}
                </VideoWrapper>
                <h3>KLANAD Spółka z ograniczoną odpowiedzialnością realizuje projekt dofinansowany z Funduszy Europejskich „Mowa druku– nowa technologia interaktywnych etykiet produktowych, oferująca nowoczesny kanał dotarcia i zaangażowania konsumentów”</h3>
                <p>
                  Celem projektu jest wdrożenie nowej technologii obejmującej sposób rozpoznawania oraz zabezpieczania etykiet produktowych
                  <br />
                  Planowanym efektem realizacji projektu będzie znaczące zwiększenie konkurencyjności firmy na polskim rynku poligraficznym
                  <br />
                  Wartość projektu: 1 593 000,00 PLN
                  <br />
                  Dofinansowanie projektu z UE: 876 150,00 PLN
                </p>
            </Container>
        </VideoSection>
    );
};

export default Videos;