import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import gsap from 'gsap';
import { useForm } from "react-hook-form";
import Container from '../../components/Container/Container'
import SectionHeader from "../Typography/SectionHeader";
import {ErrorMessage} from "@hookform/error-message";
import {ButtonStyles} from "../Buttons/ButtonStyles";

const mobilePadding = css`
    @media (max-width: 768px) {
        padding: 92px 5px;
    }
`;

const SectionContact = styled.section`
    ${mobilePadding};
    padding: 92px 0;
    
    .thanks {
        display: grid;
        place-items: center;
        opacity: 0;
    }
    
    p {
        text-align: center;
        margin-bottom: 50px;
    }
`;

const ContentWrapper = styled.div`
    grid-column-gap: 60px;
    width: 100%;
    padding: 48px;
    border-radius: 50px;
    margin-top: 48px;
    box-shadow: 0 0 45px -15px rgba(0,0,0,0.25);
    opacity: 1;
    
    .checkbox_wrapper {
        display: flex;
        align-items: center;
        margin: 24px 0 48px;
        position: relative;
        
        input {          
            width: auto;
        }
        
        label {
            text-align: left;
            padding-left: 15px;
            display: block;
            color: #A5A5A5;
            font-size: 60%;
            line-height: 1.5;
        }
    }
    
    h4 {
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 48px;
        text-align: center;
    }
    
    @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 32px 15px;
    
    h4 {
        margin-bottom: 32px;
    }
    
    .checkbox_wrapper {
        align-items: flex-start;
    
        input {
            width: 10vw;
            max-width: 50px;
        }
    } 
    
    }
`;

const StyledForm = styled.form`
    text-align: right;
    margin-bottom: 0;
    
    @media (max-width: 768px) {
        text-align: center;
    }
    
    small {
        font-size: 70%;
        position: absolute;
        left: 0;
        bottom: 0;
        color: red;
        transform: translateY(100%);
    }
    
    ul {
        list-style: none;
        margin: 0;
    
        li {
            display: flex;
            flex-direction: column;
        }
    }
`;

const UserSumbmission = styled.div`
    display: block;
`;

const NameAndMail = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 48px;
    grid-row-gap: 10px;
    
    @media (max-width: 762px) {
        & > div:first-child {
            margin-bottom: 10px;
        }
    }
`;

const Message = styled.div`
    margin-top: 48px;
    
    @media (max-width: 762px) {
        margin-top: 20px;
    }  
`;

const InputWrapper = styled.div`
    position: relative;
    
    //&::before, &::after {
    //    position: absolute;
    //    content: '';
    //    bottom: 0;
    //    width: 50%;
    //    background-color: var(--blue);  
    //    height: 1px;      
    //    transform: scale(0);
    //    transition: transform .3s ease-out;
    //}
    //
    //&::before {
    //    left: 50%;
    //    transform-origin: left;
    //}
    //
    //&::after {
    //    right: 50%;
    //    transform-origin: right;
    //}
    //
    //&:hover::before, &:hover::after {
    //    transform: scale(1);
    //}
    
    @media (max-width: 768px) {
        margin: 15px 0;
    }
    
    input, textarea {\
        width: 100%;
        background: #f7f7f7;
        //background: rgb(250, 252, 255);
        border: none;
        border-bottom: 1px solid #E3E3E3;
        color: var(--textNormal);
        padding: 8px 10px;
        font-size: 1rem;
        outline: none;
        border-radius: 8px 8px 0 0;
        -webkit-appearance: none;
    
        &:focus-visible, &:focus, &:active {
            //outline: 1px solid var(--blue);
            outline: none;
            border-bottom: 1px solid var(--blue);
        } 
    }
  
    label {
        position: absolute;
        top: 14.5px;
        left: 14.5px;
        transform-origin: left;
        transition: transform .3s ease-in-out;
        color: #A5A5A5;
        cursor: text;
        font-size: 70%;
    }
    
    input:focus ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:focus + label,
    textarea:not(:placeholder-shown) ~ label {
        border: none;
        color: #868686;
        transform: translate(-10px, -35px) scale(.7);
    }
`;

const ContactSection = () => {

    const formRef = useRef();
    const formFieldRef = useRef();
    const { register, handleSubmit, errors, reset } = useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [formSent, setFormSent] = useState('not_sent');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [userName, setUserName] = useState();

    function submitForm() {
        setBtnDisabled(true);
        setFormSent('sending');
        reset();
        setUserName(name);

        const functionPath = `/.netlify/functions/sendgrid`;
        const functionOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                name,
                email,
                msg,
            }),
        };

        fetch(functionPath, functionOptions)
            .then(response => {
                if (response.ok) {
                    setName('');
                    setEmail(' ');
                    setMsg('');
                    setFormSent('after_sent');
                    // setBtnDisabled(false);
                } else {
                    setFormSent('fail');
                }

            })
            .catch(err => {
                console.error('There was an ERROR! 🔴',err)
            });
    }

    useEffect(() => {
        gsap.set('.contact_form', { scale: .8, y: 25, autoAlpha: 0, duration: 1.5, ease: 'power4.out' });
        gsap.to('.contact_form', { scale: 1, y: 0, autoAlpha: 1,
            scrollTrigger: {
                onEnter: () => gsap.to('.contact_form', { scale: 1, y: 0, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.contact_form', { scale: .8, y: 25, autoAlpha: 0 }),
                trigger: '.contact_form',
                start: '-15% 75%',
                end: '-5% 85%',
            }
        });
        gsap.fromTo('.thanks', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5});
    }, []);


    return (
        <SectionContact>
            <Container>
                <SectionHeader>Masz pytanie? Odezwij się</SectionHeader>
                <ContentWrapper className="contact_form">
                    { formSent === 'not_sent' && <h4>Zostaw swoje dane kontaktowe i zadaj pytanie. Odpowiemy tak szybko, jak to możliwe.</h4>}
                    { formSent === 'sending' && <h4>Wysyłanie...</h4>}
                    { formSent === 'after_sent' && <h4>Dziękujemy, {userName}. Otrzymaliśmy Twoją wiadomość. Odezwiemy się już wkrótce!</h4>}
                    { formSent === 'sent' && <h4>Dziękujemy, {userName}. Otrzymaliśmy Twoją wiadomość. Odezwiemy się już wkrótce!</h4>}
                    { formSent === 'fail' && <h4>Przepraszamy, {userName}. Wygląda na to, że mamy jakąś awarię. Zapraszamy do bezpośredniego kontaktu mailowego: <a href="mailto:kontakt@mowadruku.pl">kontakt@mowadruku.pl</a></h4>}
                    <StyledForm
                        ref={formRef}
                        name="contact" method="post"
                        action="/success">
                        <UserSumbmission>
                            <NameAndMail>
                                <InputWrapper>
                                    <input
                                        type="text"
                                        placeholder=" "
                                        name="name"
                                        id="name"
                                        onChange={e => setName(e.target.value)}
                                        ref={register({
                                            required: true
                                        }, formFieldRef)}
                                    />
                                    <label htmlFor="name">Imię</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="name"
                                        message="To pole jest wymagane."
                                        render={({ message}) => <small>{message}</small>}
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <input
                                        type="email"
                                        placeholder=" "
                                        name="email"
                                        id="email"
                                        onChange={e => setEmail(e.target.value)}
                                        ref={
                                            register({
                                                required: true,
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: `Podano niepoprawny adres e-mail.`
                                                }}, formFieldRef)}
                                    />
                                    <label htmlFor="email">Adres e-mail</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        message="Podano niepoprawny adres e-mail."
                                        render={({ message}) => <small>{message}</small>}
                                    />
                                </InputWrapper>
                            </NameAndMail>
                            <Message>
                                <InputWrapper>
                                                <textarea
                                                    name="message"
                                                    placeholder=" "
                                                    id="message"
                                                    onChange={e => setMsg(e.target.value)}
                                                    rows="5"
                                                    ref={register({
                                                        required: true
                                                    }, formFieldRef)}
                                                />
                                    <label htmlFor="message">Wiadomość</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="message"
                                        message="To pole jest wymagane."
                                        render={({ message}) => <small>{message}</small>}
                                    />
                                </InputWrapper>
                            </Message>
                        </UserSumbmission>
                        <div className="checkbox_wrapper">
                            <input
                                type="checkbox"
                                id="consent"
                                name="consent"
                                ref={register({ required: true}, formFieldRef)}
                            />
                            <label className="small" htmlFor="consent">
                                Wyrażam zgodę na przetwarzanie moich danych osobwych w celu obsługi tego formularza przez firmę MowaDruku sp. z o.o.
                            </label>
                            <ErrorMessage
                                errors={errors}
                                name="consent"
                                message="Wyrażenie zgody jest niezbędne do przesłania formularza."
                                render={({ message}) => <small>{message}</small>}
                            />
                        </div>
                        <ButtonStyles
                            onClick={handleSubmit(submitForm)}
                            disabled={btnDisabled === true}
                        >
                            { formSent === 'not_sent' && 'Wyślij'}
                            { formSent === 'sent' && 'Wyślij'}
                            { formSent === 'sending' && 'Wysyłanie...'}
                            { formSent === 'after_sent' && 'Wiadomość wysłana'}
                            { formSent === 'fail' && 'Wystąpił błąd'}
                        </ButtonStyles>
                    </StyledForm>
                </ContentWrapper>
            </Container>
        </SectionContact>
    );
};

export default ContactSection;
