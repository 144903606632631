import React, {useEffect} from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SvgBackground from '../../../static/subhero_bg.svg';
import Container from "../Container/Container";
import PhoneGraphic from '../../../static/subhero_graphic.svg';

const SubHeroSection = styled.section`
    position: relative;
    margin-top: -50px;
    padding-bottom: 25px;
    
    @media (max-width: 768px) {
        margin-top: unset;
        padding-top: 16px;
        padding-bottom: unset;
    }
`;

const StyledSvgBackgrond = styled(SvgBackground)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    
    path {
        fill: var(--gray);        
    }
    
    @media (max-width: 768px) {       
        height: 150%;
      
        path {
          fill: var(--blackHeroBg);
        }
    }
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    place-items: center;
    grid-gap: 92px;
    
    .svg_wrapper {
        text-align: right;
        opacity: 0;
        
        svg {
            position: static;
            height: 100%;
            width: 100%;
        }
    }
    
    p {
        //clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
        opacity: 0;
    }
    
    @media (max-width: 768px) {
        grid-template-columns: auto 1fr;
        height: inherit;
        align-content: baseline;
        grid-gap: 24px;
        
        p {
            color: var(--white);
        }
        
        .txt {
            order: 2;
        }
        
        .svg_wrapper {
                display: grid;
                align-self: self-start;
            
            svg {
                width: 10vw;
                height: auto;
            }
        }
    }
`;



const SubHero = () => {

    const data = useStaticQuery(graphql`
        query SubHeroGraphic {
#            file(name: {eq: "subhero_graphic"}) {
#                childImageSharp {
#                    fluid (maxHeight: 400, maxWidth: 300, quality: 100) {
#                        ...GatsbyImageSharpFluid_tracedSVG
#                    }
#                }
#            }
            datoCmsHome {
                subheroP
            }
        }
    `);

    useEffect(() => {

        const tl = gsap.timeline();
        tl.fromTo('.content_wrapper p', { x: -50 }, { x: 0, autoAlpha: 1, duration: 1, ease: 'power4.out'}, '+=1.5')
           .to('.content_wrapper .svg_wrapper', { autoAlpha: 1, duration: 4, ease: 'power4.out' }, '<+=1.75')
        tl.play();

        // const startAndEnd = '20% 50%';

        // ScrollTrigger.create({
        //     trigger: ".content_wrapper",
        //     onEnter: () => tl.play(),
        //     onEnterBack: () => tl.reverse(),
        //     start: startAndEnd,
        //     end: startAndEnd,
        //     markers: true,
        // })

        // let timeline = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".content_wrapper",
        //         onEnter: () => timeline.play(),
        //         onEnterBack: () => timeline.reverse(),
        //         start: startAndEnd,
        //         end: startAndEnd,
        //     }
        // });



        // const delay = 2;
        //
        // gsap.to('.content_wrapper p', { clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', duration: 2, delay: 5, ease: 'power4.out', scrollTrigger: {
        //         trigger: '.content_wrapper h2',
        //         start: '-15% 85%',
        //         end: '-15% 85%',
        // }}, '+=5');
        // gsap.to('.content_wrapper .svg_wrapper svg', { autoAlpha: 1, duration: 4, delay: 5, ease: 'power4.out', scrollTrigger: {
        //         trigger: '.content_wrapper .svg_wrapper',
        //         start: '-15% 85%',
        //         end: '-15% 85%',
        //     }}, '+=5');

    }, []);

    return (
        <SubHeroSection>
            <StyledSvgBackgrond />
            <Container>
                <GridWrapper className="content_wrapper">
                    <div className="txt" dangerouslySetInnerHTML={{ __html: data.datoCmsHome.subheroP }}/>
                    <div className="svg_wrapper">
                         <PhoneGraphic/>
                     </div>
                </GridWrapper>
            </Container>
        </SubHeroSection>
    )
};

export default SubHero;