import React from 'react';
import { ButtonStyles } from "./ButtonStyles";

const ButtonHero = ({ blue, hero, opacity0, children, scrollTo }) => {

    return (
        <ButtonStyles className="btn_hero" blue={blue} hero={hero} opacity0={opacity0} onClick={scrollTo}>
            {children}
        </ButtonStyles>
    )
};

export default ButtonHero;


