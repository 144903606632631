import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import EmailIcon from '../../../static/email.svg';
import Facebook from '../../../static/facebook.svg'
import YouTube from '../../../static/youtube.svg'
import Instagram from '../../../static/instagram.svg'
import Container from "../Container/Container";
import SectionHeader from "../Typography/SectionHeader";
import {graphql, useStaticQuery} from "gatsby";
import BGImage from 'gatsby-background-image';


const FooterSection = styled.footer`
    position: relative;
    background: var(--black);
    text-align: center;
    
    a {
        text-decoration: none;
        color: var(--white);
    }
    
    .mail {
    
        .animation_wrapper {
            opacity: 0;
            display: flex;
        }
    
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 48px 0;
        transition: filter .3s ease-in;
        
        &:hover {
            filter: brightness(.85);
        }
        
        span {
            color: #6c6c6c;
        }
        
        svg {
            margin-right: 8px;
            
            path {
                fill: var(--white);
            }
        }
    }
    .sm_icons {
        display: flex;
        justify-content: center;
        
        svg {
            margin: 0 16px;
            transition: filter .3s ease-in;
            fill: var(--gray);
            
            &:hover {
                filter: brightness(.85);
            }
            
            @media (max-width: 768px) {
                width: 50%;
                height: auto;
            }
        }
    }

  .eu {
    background-color: white;
  }
`;

const StyledBgImage = styled(BGImage)`
    padding: 92px 0;
    
    &:before, &:after {
        filter: brightness(.4);
        background-position: 50% 70%!important;
    }
`;

const Footer = () => {

    const data = useStaticQuery(graphql`
        query {
            datoCmsHome {
                footerEmail
            }
            file(name: {eq: "footer-bg"}) {
                id
                childImageSharp {
                    fluid( maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    useEffect(() => {
        gsap.to('.animation_wrapper', { ease: 'power4.out', duration: 1.5,
            scrollTrigger: {
                trigger: '.animation_wrapper',
                onEnter: () => gsap.to('.animation_wrapper', { autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.animation_wrapper', { autoAlpha: 0 }),
                start: '-30% 85%',
                end: '-30% 85%',
            }
        });

    }, []);

    useEffect(() => {

        const cards = gsap.utils.toArray('.sm_icons div');

        gsap.set('.sm_icons div a', {
            autoAlpha: 0,
            y: 25,
        });

        cards.forEach((item, i) => {
            item.tl = gsap.timeline({ paused: true })
            .to(item.querySelector('.sm_icons div a'), {
                autoAlpha: 1,
                y: 0,
                duration: .5,
            })
        });

        function show(batch) {
            batch.forEach(function(target, i) {
                gsap.delayedCall(i * 0.15, function() {
                    target.tl.play();
                });
            });
        }

        function reverse(batch) {
            batch.forEach(function(target, i) {
                gsap.delayedCall(i * 0.15, function() {
                    target.tl.reverse();
                })
            })
        }

        ScrollTrigger.batch(cards, {
            onEnter: show,
            onEnterBack: reverse,
            trigger: '.sm_icons',
            start: '0 90%',
            end: '0 90%',
        });

    }, []);

    return (
        <FooterSection className="footer">
            <StyledBgImage fluid={data.file.childImageSharp.fluid}>
                <Container>
                    <SectionHeader white>Kontakt</SectionHeader>
                    <div className="mail">
                        <div className="animation_wrapper">
                            <EmailIcon/>
                            <span>
                                <a href={`mailto:${data.datoCmsHome.footerEmail}`}>
                                    {data.datoCmsHome.footerEmail}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="sm_icons">
                        <div>
                            <a href="https://facebook.com/mowadruku" target="_blank" rel="noreferrer">
                                <Facebook/>
                            </a>

                        </div>
                        <div>
                            <a href="https://www.youtube.com/channel/UCG_RiscsUUrGgQN6ELhsp5Q" target="_blank" rel="noreferrer">
                                <YouTube/>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/mowadruku/" target="_blank" rel="noreferrer">
                                <Instagram/>
                            </a>
                        </div>
                    </div>
                </Container>
            </StyledBgImage>
          <div className="eu">
            <img
              src="/dotacja-info.jpg"
              height={500}
              width={300}
            />
          </div>
        </FooterSection>
    )
};

export default Footer;