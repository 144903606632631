import React, { useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { graphql, useStaticQuery } from "gatsby";
// import useReactSimpleMatchMedia from 'react-simple-matchmedia'
import Image from 'gatsby-image'
import Container from "../Container/Container";
import VideoPlayer from '../../utils/VideoPlayer';
// import LogoGLB from '../../utils/LogoGLB';
// import {Canvas} from "react-three-fiber";
// import LogoOptimized from "../../utils/LogoOptimized";
// import ArModel from "../../utils/ArModel";
// import Logo from '../../../static/logo.svg';
import Logo3D from "../../utils/Logo3D";
import ButtonHero from "../Buttons/ButtonHero";
import scrollTo from "gatsby-plugin-smoothscroll";

const height = '550px';
const imgIndent = 33;

const HeroSection =  styled.section`
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: var(--gray);
    height: ${height};
    
    h1 {
        margin-bottom: 24px;
    }
    
    p {
        margin-bottom: 0;
    }
    
    h1, p {
        font-weight: normal;
        opacity: 0;
    }
    
    .grid {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 2fr;
        position: relative;
        //padding: 64px 0;
        grid-gap: 48px;
    }
    
    .text_wrapper {
        display: grid;
        color: white;
        
        .button_wrapper {
            opacity: 0;
        }
    }
    
    @media (max-width: 768px) {
      
        h1 {
            font-size: 130%;
            display: grid;
            align-self: end;
            margin-bottom: 0;
        }
        
        h1, p {
            margin: 16px 0;
        }
        
        .grid {
            padding: unset;
            grid-template-columns: 1fr;
            align-items: unset;
            height: auto;
        }
        
        .text_wrapper {
            //height: fit-content;
            //align-self: end;
            align-content: start;
        
            div:first-child {
                align-self: center;
                height: fit-content;
            }
        }
    }
`;

const ImageWrapper = styled.div`
    --imageIndent: 33;
    width: 100%;
    height: 100%;
    padding-left: ${imgIndent}vw;
    position: absolute;
    
    .gatsby-image-wrapper {
        height: ${height};
        
        img {
            width: calc(100vw - ${imgIndent}vw)!important;
            border-radius: 50px 0 0 50px;
            filter: brightness(0.45);
            height: ${height};
        }
    }
    
    @media (max-width: 768px) {
        padding-left: unset;
        
        .gatsby-image-wrapper img, video {
            width: 100%!important;
            border-radius: 0;
            height: 100%;
        }
        
        .gatsby-image-wrapper {
            height: 100%;
        }
    }
`;

const MediaBackgroundWrapper = styled.div`
    width: 100%;
    height: 100%;
    //filter: brightness(.45);
    border-radius: 50px 0 0 50px;  
    background: #181818;
    
    @media (max-width: 768px) {
        border-radius: 0;    
      
        video {
            width: 100%!important;
        }
    }
    
    video {
        height: inherit;
        filter: brightness(.4);
        border-radius: 50px;
    }
    
    & > div {
        width: 100%;
        height: 100%; 
        
        &::before, &::after, video {
            width: calc(100vw - ${imgIndent}vw);
        }
    }
`;

const Logo3DWrapper = styled.div`
    width: auto;
    
    @media (max-width: 768px) {
        height: 125px;
        width: 125px;
        margin: 1vh auto 24vh;
    }
    
    canvas {
        border-radius: 50px;
        border: 2px solid black;
    }
`;

// const LogoLazy = React.lazy(() =>
//     import("../../utils/Logo3D.js")
// );
//
// const VideoHeader = React.lazy(() =>
//     import("../../utils/VideoPlayer.js")
// );

const Hero = () => {

    useEffect(() => {

        gsap.set(['.text_wrapper h1, .text_wrapper .btn_hero'], { x: 50, autoAlpha: 0 });
        const tl = gsap.timeline();
        tl
            .to('.text_wrapper h1',{ x: 0, autoAlpha: 1, duration: 1, ease: 'power4.out'}, '+=.25')
            .to('.text_wrapper .btn_hero',{ x: 0, autoAlpha: 1, duration: 1, ease: 'power4.out'}, '-=.5')

    }, []);




    const data = useStaticQuery(graphql`
        query ParallaxSectionQuery {
            datoCmsHome {
                heroHeader1
                heroP
                headerImg {
                    fluid (maxHeight: 350, maxWidth: 900) {
                        ...GatsbyDatoCmsFluid
                    }
                }
                heroVideo {
                    url
                }
            }
        }
    `);


    const isSSR = typeof window === "undefined";


    // const isMobile = useReactSimpleMatchMedia('(max-width: 768px)');

    return (
        <HeroSection>
            <ImageWrapper>
                <MediaBackgroundWrapper>
                    {!isSSR && (
                        <React.Suspense fallback={ <Image fluid={data.datoCmsHome.headerImg.fluid}/> }>
                            <VideoPlayer />
                        </React.Suspense>
                    )}
                    {/*<VideoHeader fallback={*/}
                    {/*    <Image fluid={data.datoCmsHome.headerImg.fluid}/>*/}
                    {/* }/>*/}
                </MediaBackgroundWrapper>
            </ImageWrapper>
            <Container displayGrid>
                <div className="grid">
                    <Logo3DWrapper className="logo">
                        {/*<Canvas>*/}
                        {/*    <LogoOptimized />*/}
                        {/*</Canvas>*/}
                        {/*<ArModel/>*/}
                        {/*<Logo/>*/}
                        {/*<Canvas>*/}
                        {/*    <Suspense fallback={null}>*/}
                        {/*        <LogoOptimized/>*/}
                        {/*    </Suspense>*/}
                        {/*</Canvas>*/}
                        {/*<LogoGLB/>*/}
                        {/*{!isSSR && (*/}
                        {/*    <React.Suspense fallback={ <Logo/> }>*/}
                        {/*        <LogoLazy  />*/}
                        {/*    </React.Suspense>*/}
                        {/*)}*/}
                        <Logo3D />
                    </Logo3DWrapper>
                    <div className="text_wrapper">
                        <div dangerouslySetInnerHTML={{ __html: data.datoCmsHome.heroHeader1 }}/>
                        <div className="hero_button">
                            <ButtonHero opacity0 hero scrollTo={() => scrollTo('#download')}>pobierz za darmo</ButtonHero>
                        </div>
                        {/*<div dangerouslySetInnerHTML={{ __html: data.datoCmsHome.heroP }}/>*/}
                    </div>
                </div>
            </Container>
        </HeroSection>
    )
};

export default Hero;