import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Container from "../Container/Container";
import SectionHeader from "../Typography/SectionHeader";
import DownloadBg from '../../../static/download_bg.svg';
import DownloadPhone from '../../../static/download_phone.svg'
import {graphql, useStaticQuery} from "gatsby";
import Image from 'gatsby-image';

const DownloadSection = styled.section`
    position: relative;
`;

const StyledDownloadBg = styled(DownloadBg)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    position: relative;
    z-index: 1;
    place-items: center;
    
    .phone_graphic_wrapper {
        opacity: 0
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        
        .one {
            order: 2;
        }
        
        .phone_graphic_wrapper {
            margin-bottom: 32px;
            text-align: center;
        }
        
        svg {
            width: 35%;
            height: auto;
        }
    }
    
`;

const StyledDownloadPhone = styled(DownloadPhone)`
    transform: rotate(13deg);
`;

const DownloadBadges = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin-top: 32px;
    
    .badge_wrapper {
        opacity: 0;
        display: inline-grid;
        width: max-content;
      
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        text-decoration: none;
        width: 100%;
      }
    }
    
    a {
        transition: transform .3s ease-in-out;
        cursor: pointer;
        display: inline-grid;
        width: min-content;
    }
    
    
    a:hover {
        //transform: skew(-5deg);
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 16px;
        
        a {
            //transform: scale(.85);  
        }
    }
`;

const Downlaod = () => {

    // const data = useStaticQuery(graphql`
    //     query DownloadQuery {
    //         appstore: file(name: {eq: "pobierz-z-appstore"}) {
    //             childImageSharp {
    //                 fixed (height: 56) {
    //                     ...GatsbyImageSharpFixed
    //                 }
    //             }
    //         }
    //         googleplay: file(name: {eq: "pobierz-z-googleplay"}) {
    //             childImageSharp {
    //                 fixed (height: 56) {
    //                     ...GatsbyImageSharpFixed
    //                 }
    //             }
    //         }
    //     }
    // `);

    useEffect(() => {
        const startAndEnd = '0 85%';

        gsap.set('.phone_graphic_wrapper', { x: 50, autoAlpha: 0, ease: 'power4.out', duration: 1.5 });
        gsap.to('.phone_graphic_wrapper', {
            scrollTrigger: {
                trigger: '.phone_graphic_wrapper',
                onEnter: () => gsap.to('.phone_graphic_wrapper', { x: 0, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.phone_graphic_wrapper', { x: 50, autoAlpha: 0 }),
                start: startAndEnd,
                end: startAndEnd,
            }
        });

        gsap.set('.badge_wrapper', { y: 25, autoAlpha: 0 });
        ScrollTrigger.batch(".badge_wrapper", {
            interval: 0.1,
            batchMax: 3,
            onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: .3, ease: 'power4.out', duration: 1 }),
            onEnterBack: batch => gsap.to(batch, { y: 25, autoAlpha: 0, stagger: .3, ease: 'power4.out', duration: 1 }),
            start: "-15% 85%",
            end: "-15% 85%",
        });

    }, []);

    return (
        <DownloadSection id="download">
            <StyledDownloadBg/>
            <Container>
                <GridWrapper>
                    <div className="one">
                        <SectionHeader white={true}>
                            Pobierz i sprawdź <br/> za darmo!
                        </SectionHeader>
                        <DownloadBadges className="badges">
                            <div className="badge_wrapper">
                                <a href="https://apps.apple.com/mk/app/mowa-druku/id1495964094">
                                  <span>Pobierz z </span>
                                  <span>App Store</span>
                                    {/*<Image fixed={data.appstore.childImageSharp.fixed}/>*/}
                                </a>

                            </div>
                            <div className="badge_wrapper">
                                <a href="https://play.google.com/store/apps/details?id=pl.mowadruku">
                                  <span>Pobierz z </span>
                                  <span>Google Play</span>
                                    {/*<Image fixed={data.googleplay.childImageSharp.fixed}/>*/}
                                </a>
                            </div>
                        </DownloadBadges>
                    </div>
                    <div className="phone_graphic_wrapper">
                        <StyledDownloadPhone/>
                    </div>
                </GridWrapper>
            </Container>
        </DownloadSection>
    )
};

export default Downlaod;