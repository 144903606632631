import React from 'react';
import { gsap } from 'gsap';
import {useEffect} from "react";
import { ButtonStyles } from "./ButtonStyles";

const Button = ({ blue, hero, children, scrollToSection }) => {

    useEffect(() => {
        const startAndEnd = '-30% 85%';
        gsap.set('.btn_examples', { scale: .9, autoAlpha: 0, duration: 1, ease: 'power4.out' });
        gsap.to('.btn_examples', {
            scrollTrigger: {
                onEnter: () => gsap.to('.btn_examples', { scale: 1, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.btn_examples', { scale: .9, autoAlpha: 0 }),
                trigger: '.btn_examples',
                start: startAndEnd,
                end: startAndEnd,
            }
        });
    }, []);

    return (
        <ButtonStyles className="btn_examples" blue={blue} hero={hero} onClick={scrollToSection}>
            {children}
        </ButtonStyles>
    )
};

export default Button;


