import React, {useEffect} from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container/Container";
import WomanInBlob from '../../../static/WomanInBlob.svg';
import ManInBlob from '../../../static/ManInBlob.svg';
import useReactSimpleMatchMedia from "react-simple-matchmedia";

const UsecasesOfAppSection = styled.section`
    padding: 92px 0;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ reverted }) => reverted ? '3fr 1fr' : '1fr 3fr'};
    grid-gap: 48px;
    align-items: center;
    margin-bottom: 24px;
    
    .svg_wrapper {
        transform-origin: center;
    }
    
    .txt_wrapper {
        opacity: 0;
    }
        
    svg, p {
        //opacity: 0;
    }
    
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 8px;
        place-items: center;
        
        svg {
            width: 30%;
            height: auto;
        }
        
        div.txt_wrapper {
            order: 2;
            padding: 1.5em;
            background: white;
            box-shadow: 0 0 45px -15px rgba(0,0,0,0.25);
            border-bottom: 1px solid var(--blue);
            border-radius: 16px 16px 0 0;
        }
    }   
`;

const UsecasesOfApp = () => {

    const data = useStaticQuery(graphql`
        query  {
            datoCmsHome {
                usecaseOfAppP1
                usecaseOfAppP2
            }
        }
    `);

    useEffect(() => {
        const startAndEnd = '-15% 75%';

        gsap.to('.usecases_wrapper.one svg', {
            scrollTrigger: {
                onEnter: () => gsap.to('.usecases_wrapper.one svg', { y: 0, autoAlpha: 1, }),
                onEnterBack: () => gsap.to('.usecases_wrapper.one svg', { y: 25, autoAlpha: 0 }),
                trigger: '.usecases_wrapper.one',
                start: startAndEnd,
                end: startAndEnd,
            }
        });

        gsap.to('.usecases_wrapper.one .txt_wrapper', {
            scrollTrigger: {
                onEnter: () => gsap.to('.usecases_wrapper.one .txt_wrapper', { y: 0, autoAlpha: 1, }),
                onEnterBack: () => gsap.to('.usecases_wrapper.one .txt_wrapper', { y: 25, autoAlpha: 0 }),
                trigger: '.usecases_wrapper.one .txt_wrapper',
                start: '-30% 75%',
                end: '-30% 75%',
            }
        });

        gsap.to('.usecases_wrapper.two svg', {
            scrollTrigger: {
                onEnter: () => gsap.to('.usecases_wrapper.two svg', { y: 0, autoAlpha: 1, }),
                onEnterBack: () => gsap.to('.usecases_wrapper.two svg', { y: 25, autoAlpha: 0 }),
                trigger: '.usecases_wrapper.two',
                start: startAndEnd,
                end: startAndEnd,
            }
        });

        gsap.to('.usecases_wrapper.two .txt_wrapper', {
            scrollTrigger: {
                onEnter: () => gsap.to('.usecases_wrapper.two .txt_wrapper', { y: 0, autoAlpha: 1, }),
                onEnterBack: () => gsap.to('.usecases_wrapper.two .txt_wrapper', { y: 25, autoAlpha: 0 }),
                trigger: '.usecases_wrapper.two .txt_wrapper',
                start: '-30% 75%',
                end: '-30% 75%',
            }
        });


    },[]);

    const isntMobile = useReactSimpleMatchMedia('(min-width: 768px)');


    return (
        <UsecasesOfAppSection>
            <Container>
                <GridWrapper className="usecases_wrapper one">
                    {isntMobile && <div className="svg_wrapper">
                        <WomanInBlob />
                    </div>}
                    <div className="txt_wrapper" dangerouslySetInnerHTML={{ __html: data.datoCmsHome.usecaseOfAppP1 }} />
                </GridWrapper>
                <GridWrapper className="usecases_wrapper two" reverted>
                    <div className="txt_wrapper" dangerouslySetInnerHTML={{ __html: data.datoCmsHome.usecaseOfAppP2 }} />
                    {isntMobile && <div className="svg_wrapper">
                        <ManInBlob />
                    </div>}
                </GridWrapper>
            </Container>
        </UsecasesOfAppSection>
    )
};

export default UsecasesOfApp;