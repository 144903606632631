import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const VideoPlayer = () => {

    const data = useStaticQuery(graphql`
        query {
            datoCmsHome {
                heroVideo {
                    url
                }
            }
        }
    `);

    return (


        <div dangerouslySetInnerHTML={{ __html: `
        <video autoPlay muted playsinline loop>
            <source src="${data.datoCmsHome.heroVideo.url}" type="video/mp4"/>
        </video>`
        }}
    />
    )
};

export default VideoPlayer;