import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import {graphql, useStaticQuery} from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import BgImage from 'gatsby-background-image-es5';
import Container from "../Container/Container";
import Button from '../Buttons/Button';

const SeeExamplesSection = styled.section`
    min-height: 300px;
    text-align: center;
    
    & > div {
        padding: 92px 0;
        
        &::before, &::after {
            filter: brightness(.6);
            background-attachment: fixed;
            
            @media (max-width: 768px) {
                background-attachment: unset;
            }
        } 
    }
    
    p {
        font-weight: bold;
        color: white;
        margin-bottom: 64px;
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }
`;

const SeeExamples = () => {

    const data = useStaticQuery(graphql`
        query {
            datoCmsHome {
                seeExamplesImg {
                    fluid (maxHeight: 350, maxWidth: 900) {
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                seeExamplesText
            }
        }
    `);

    useEffect(() => {

        // gsap.utils.toArray(".see_examples").forEach(section => {
        //     const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;
        //     gsap.fromTo(section,{ y: 0 }, {
        //         scrollTrigger: {
        //             trigger: section.parentElement,
        //             scrub: true
        //         },
        //         y: 0,
        //         ease: "none"
        //     });
        // });


         // gsap.utils.toArray(".parallax").forEach((section, i) => {
        // if (typeof window !== "undefined") {
        // }
        // const parralaxImage = gsap.utils.toArray(".see_examples > div::before");
        //
        // gsap.to(parralaxImage, {
        //     backgroundPosition: `50% ${-innerHeight / 2}px`,
        //     ease: "none",
        //     scrollTrigger: {
        //         trigger: '.see_examples',
        //         scrub: true}
        //     }
        // );
        // });

        const startAndEnd = '-15% 75%';
        gsap.set('.see_examples p', { duration: 2.5, ease: 'linear' });
        gsap.to('.see_examples p', {
            scrollTrigger: {
                onEnter: () => gsap.to('.see_examples p', { clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)' }),
                onEnterBack: () => gsap.to('.see_examples p', { clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)' }),
                trigger: '.see_examples p',
                start: startAndEnd,
                end:startAndEnd,
            }
        });

        // gsap.to('.see_examples h2', { duration: 1, clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)', scrollTrigger: {
        //         trigger: '.see_examples h2',
        //         start: startAndEnd,
        //         end: startAndEnd,
        //     }
        // });
        //


        // gsap.fromTo(paras[index], { y: 25 }, { y: 0, autoAlpha: 1, duration: 1, scrollTrigger: {
        //         trigger: paras[index],
        //         start: startAndEnd,
        //         end: startAndEnd,
        //         markers: true,
        //     }
        // });

    },[]);

    return (
        <SeeExamplesSection className="see_examples">
            <BgImage fluid={data.datoCmsHome.seeExamplesImg.fluid}>
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: data.datoCmsHome.seeExamplesText }}/>
                    <Button opacity0 scrollToSection={() => scrollTo('#video_section')} blue>zobacz przykład</Button>
                </Container>
            </BgImage>
        </SeeExamplesSection>
    )
};

export default SeeExamples;