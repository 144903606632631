import React from "react"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SEO from "../components/seo";
import Hero from '../components/IndexPage/Hero';
import SubHero from '../components/IndexPage/SubHero';
import WorkingOfApp from '../components/IndexPage/WorkingOfApp';
import Download from '../components/IndexPage/Download';
import UsecasesOfApp from '../components/IndexPage/UsecasesOfApp';
import SeeExamples from '../components/IndexPage/SeeExamples';
import Videos from '../components/IndexPage/Videos';
import Contact from '../components/IndexPage/ContactForm';
import Footer from '../components/IndexPage/Footer';
import useReactSimpleMatchMedia from "react-simple-matchmedia";
import MobileCTA from "../components/IndexPage/MobileCTA";
import scrollTo from 'gatsby-plugin-smoothscroll';

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const IndexPage = () => {
    const isMobile = useReactSimpleMatchMedia('(max-width: 768px)');

    return (
        <>
            <SEO title="MowaDruku - interaktywne etykiety" />
            <img src="/fundusze_europejskie.png" alt={"Fundusze unijne"} />
            <Hero />
            <SubHero />
            <WorkingOfApp />
            <Download />
            <UsecasesOfApp />
            <SeeExamples />
            <Videos />
            <Contact />
            <Footer />
            {isMobile && <MobileCTA scrollToSection={() => scrollTo('#download')} />}

        </>
)};

export default IndexPage
