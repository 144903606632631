import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger);
}

const StyledSectionHeader = styled.h3`
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.3;
    color: ${({ white }) => white ? 'var(--white)' : 'var(--black)'};
    margin: ${({ withMargin }) => withMargin ? '32px 0' : '0'};
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;


const SectionHeader = ({ children, white }) => {

    useEffect(() => {
        const startAndEnd = '-30% 75%';
        const H3 = gsap.utils.toArray('.section_header');
        H3.forEach((el, index) => {
            gsap.to(H3[index], {
                y: 0,
                duration: 2,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: H3[index],
                    start: startAndEnd,
                    end: startAndEnd,
                    onEnter: () => gsap.to(H3[index], { clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)'}),
                    onEnterBack: () => gsap.to(H3[index], { clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)'}),
                }});
        });

    },[]);

    return (
        <StyledSectionHeader white={white} className="section_header">
            {children}
        </StyledSectionHeader>
    )
};

export default SectionHeader;