import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ButtonStyles } from '../Buttons/ButtonStyles';

const Wrapper = styled.div`
    position: fixed;
    bottom: 1em;
    left: 50%;
    opacity: 0;  
    z-index: 15;
    transform: translateX(-50%);
`;

const MobileCTA = ({ scrollToSection }) => {

    useEffect(() => {
        gsap.set('.mobile_cta', { duration: 2.5, ease: "elastic.out(1, 0.4)" });
        gsap.fromTo('.mobile_cta', { scale: 0, y: '100%', autoAlpha: 0, }, {
            scrollTrigger: {
                trigger: '.hero_button',
                start: '100% 0',
                end: '100% 0',
                onEnter: () => gsap.to('.mobile_cta', { scale: 1, y: 0, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.mobile_cta', { scale: 0, y: '100%', autoAlpha: 0 }),
            }
        })
    }, []);

    return (
        <Wrapper className="mobile_cta">
            <ButtonStyles blue hero onClick={scrollToSection}>
                Pobierz
            </ButtonStyles>
        </Wrapper>
    )
};

export default MobileCTA;