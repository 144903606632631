import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container/Container";
import SectionHeader from "../Typography/SectionHeader";
import Blob1 from '../../../static/blob1.svg'
import Blob2 from '../../../static/blob2.svg'
import Blob3 from '../../../static/blob3.svg'
import {graphql, useStaticQuery} from "gatsby";

const WorkingOfAppSection = styled.section`
    text-align: center; 
    padding: 96px 0;
    overflow-x: hidden;
    
    @media (max-width: 768px) {
        padding: 140px 0 48px;
    }
`;

const Steps = styled.div`
    display: grid;
    grid-row-gap: 48px;
    margin-top: 48px;

    .step {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 50%;
        margin: 0 auto;
        //opacity: 0;
        
        span {
            font-weight: bold;
        }
        
        .svg_wrapper {
            position: relative;
            font-size: 3rem;
            margin-right: 72px;
            opacity: 0;
            
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: -1;
            }
        }
        
        p {
            margin: 0;
            text-align: left;
        }
        
        @media (max-width: 768px) {
            width: 100%;
            align-items: flex-start;
            
            .svg_wrapper {
                margin-right: 36px;
                font-size: 1.5rem;
    
                
                svg {
                    transform: translate(-50%,-50%) scale(.5);
                }
            }
        }
    }
`;

const WorkingOfApp = () => {

    const data = useStaticQuery(graphql`
        query  {
            datoCmsHome {
                workingOfAppStep1
                workingOfAppStep2
                workingOfAppStep3
            }
        }
    `);

    const steps = [
        {
            number: '1.',
            text: data.datoCmsHome.workingOfAppStep1,
            blob: <Blob1 />
        },
        {
            number: '2.',
            text: data.datoCmsHome.workingOfAppStep2,
            blob: <Blob2 />
        },
        {
            number: '3.',
            text: data.datoCmsHome.workingOfAppStep3,
            blob: <Blob3 />
        },
    ];


    useEffect(() => {

        // steps.forEach((el, index) => {
        //     gsap.fromTo(steps[index],{ y: 25 }, {
        //         y: 0,
        //         autoAlpha: 1,
        //         duration: 1,
        //         ease: "power4.out",
        //         scrollTrigger: {
        //             trigger: steps[index],
        //             start: startAndEnd,
        //             end: startAndEnd,
        //         }});
        // });

        // gsap.set('.step', { y: 25, ease: 'power4.out', duration: 1 });
        // ScrollTrigger.batch(['.text', '.svg_wrapper'], {
        //     onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0 }),
        //     onLeave: batch => gsap.set(batch, { autoAlpha: 0, y: 25 }),
        //     onEnterBack: batch => gsap.to(batch, { autoAlpha: 0, y: 0 }),
        //     onLeaveBack: batch => gsap.set(batch, { autoAlpha: 0, y: -25, duration: 1 }),
        //     trigger: '.step',
        //     start: startAndEnd,
        //     end: startAndEnd,
        //     markers: true,
        // });

        // gsap.set('.step .svg_wrapper', { x: -25, ease: 'power4.out', duration: 1 });
        // ScrollTrigger.batch('.step .svg_wrapper', {
        //     interval: 0.1,
        //     onEnter: batch => gsap.to(batch, { autoAlpha: 1, x: 0 }),
        //     // onLeave: batch => gsap.set(batch, { autoAlpha: 0, y: 25 }),
        //     // onEnterBack: batch => gsap.to(batch, { autoAlpha: 1, y: 0 }),
        //     // onLeaveBack: batch => gsap.set(batch, { autoAlpha: 0, y: -25 }),
        //     trigger: '.step',
        //     start: startAndEnd,
        //     end: startAndEnd,
        //     markers: true,
        // })

        // gsap.registerPlugin(ScrollTrigger);

        const steps = gsap.utils.toArray('.step');
        // gsap.set('.step', { y: 25, ease: 'power4.out', duration: 1 });

        gsap.set('.step .text', {
            autoAlpha: 0,
            x: 50,
        });
        gsap.set('.step .svg_wrapper', {
            autoAlpha: 0,
            x: -50,
        });

        steps.forEach((item, i) => {
            item.tl = gsap.timeline({ paused: true })
            .to(item.querySelector('.step .text'), {
                autoAlpha: 1,
                x: 0,
                duration: .5,
            })
            .to(item.querySelector('.step .svg_wrapper'), {
                autoAlpha: 1,
                x: 0,
                duration: .75,
            }, '<')
        });


        function show(batch) {
            batch.forEach(function(target, i) {
                gsap.delayedCall(i * 0.3, function() {
                    target.tl.play();
                });
            });
        }

        function reverse(batch) {
            batch.forEach(function(target, i) {
                gsap.delayedCall(i * 0.3, function() {
                    target.tl.reverse();
                });
            });
        }

        const startAndEnd = '0 85%';
        ScrollTrigger.batch(steps, {
            onEnter: show,
            onEnterBack: reverse,
            start: startAndEnd,
            end: startAndEnd,
        });

    }, []);

    return (
        <WorkingOfAppSection>
            <Container>
                <SectionHeader>Działanie aplikacji jest proste</SectionHeader>
                <Steps>
                    {steps.map(step => (
                        <div className="step" key={step.number}>
                            <div className="svg_wrapper">
                                <span>{step.number}</span>
                                {step.blob}
                            </div>
                            <div className="text" dangerouslySetInnerHTML={{ __html: step.text }}/>
                        </div>
                    ))}
                </Steps>
            </Container>
        </WorkingOfAppSection>
    )
};

export default WorkingOfApp;